@mixin font-face($family, $name, $file, $weight: normal) {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    src: url(../../fonts/#{$name}/#{$file}.eot?#iefix)
        format("embedded-opentype"),
      url(../../fonts/#{$name}/#{$file}.woff2) format("woff2"),
      url(../../fonts/#{$name}/#{$file}.woff) format("woff"),
      url(../../fonts/#{$name}/#{$file}.ttf) format("truetype");
  }
}

@include font-face("Proxima Nova", "proxima-nova", "regular");
@include font-face("Proxima Nova Bold", "proxima-nova", "bold");
@include font-face("Proxima Nova Light", "proxima-nova", "light");
@include font-face(
  "Proxima Nova Condensed Thin",
  "proxima-nova-condensed",
  "thin"
);
@include font-face("Proxima Nova Condensed", "proxima-nova-condensed", "light");
@include font-face(
  "Proxima Nova Condensed Bold",
  "proxima-nova-condensed",
  "bold"
);
